// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classnames from 'classnames'

import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Slider from '../slider'
import '../slider/style.less'

import Gallery from '../gallery'
import '../gallery/style.less'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale, debug = false }) => {
  let {
    type,
    subType = undefined,
    lang,
    content,
    resources,
    resource,
    caption,
  } = block

  if (isNull(lang) || isUndefined(lang)) {
    lang = 'en'
  }

  if (debug === true) {
    console.debug('debug', block, lang, locale)
  }

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br className={classnames(subType)} />
    }

    if (type === 'carousel') {
      returnThis = (
        <Slider className={classnames(subType)} resources={resources} />
      )
    }

    if (type === 'text') {
      returnThis = (
        <p className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </p>
      )
    }

    if (type === 'title') {
      returnThis = <h3 className={classnames(subType)}>{content}</h3>
    }

    if (type === 'image') {
      returnThis = (
        <div className={classnames('flow img', subType)}>
          <GatsbyImage image={getImage(resource)} />
        </div>
      )
    }

    if (type === 'gallery') {
      const images = []
      map(resources, ({ cover }) => {
        images.push({
          data: cover,
          height: cover.childImageSharp.gatsbyImageData.height,
          width: cover.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = <Gallery className={classnames(subType)} images={images} />
    }

    if (type === 'video') {
      returnThis = <Video className={classnames(subType)} url={content} />
    }

    if (type === 'audio') {
      returnThis = <Audio className={classnames(subType)} url={content} />
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'block') {
    returnThis = (
      <div className={classnames('block', 'flow', subType)}>
        <BlockParser block={childContentBlocks} locale={locale} />
      </div>
    )
  } else if (type === 'look') {
    returnThis = (
      <div className={classnames('look', 'flow', subType)}>
        <Carousel dots arrows slidesToShow={2} slidesToScroll={2} infinite>
          {map(childContentBlocks, (childContentBlock) => (
            <BlockParser block={childContentBlock} locale={locale} debug />
          ))}
        </Carousel>
      </div>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
